/*Modal Popup Component*/
.menu-overall {
    color: black;
}

.menu-header {
    font-size: 20px;
    border-bottom: 1px solid black;
    padding: 5px;
}

.menu-footer {
    border-top: 1px solid black;
    bottom: 0;
    padding: 10px;
}

/*Main Wrapper*/
.menu-main {
    display: grid;
}

/*Containers*/
.container-beginning {
    grid-column: 1;
}

.container-packs {
    grid-column: 2;

}

.container-bots {
    grid-column: 3;
}

/*Standard/Client List*/
.menu-ul-beginning {
    border-right: 1px solid black;
    list-style-type: none;
}

.menu-ul-beginning li {
    width: 120px;
    border: 1px solid black;
}

.ul-li-beginning-button {
    background-color: white;
    border: none;
    display: inline-table;
    width: 120px;
}

.ul-li-beginning-left {
    float: left;
}

.ul-li-beginning-right {
    float: right;
}


/*Pack List*/
.menu-ul-packs {
    border-right: 1px solid black;
    list-style-type: none;
}

.menu-ul-packs li {
    width: 120px;
}

.ul-li-pack_name {
    border: 1px solid black;
}

.ul-li-pack_name-button {
    background-color: white;
    border: none;
    display: inline-table;
    width: 120px;
}

.ul-li-pack_name-left {
    float: left;
}

.ul-li-pack_name-right {
    float: right
}

/*Bot List*/
.menu-ul-bots {
    position: static;
    border-right: 1px solid black;
    list-style-type: none;
}

.ul-li-bot {
    border: 1px solid black;
    width: 120px;
}

.ul-li-bot-button {
    background-color: white;
    border: none;
    display: inline-table;
    width: 120px;
}

/*Footer*/
.footer-btn {
    background-color: rgb(151, 52, 132);
    border-radius: 4px;
    width: 100px;
    height: 25px;
    font-size: 13px;
    color: white;
}

.footer-btn:disabled {
    background-color: rgba(151, 52, 132, 0.36);
    border-radius: 4px;
    width: 100px;
    height: 25px;
    font-size: 13px;
    color: white;
}

.create {
    float: left;
}

.create_from {

}

.edit {
    float: right;
}