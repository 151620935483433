/*Modal Popup Component*/
.db-overall {
    color: black;
}

.db-header {
    text-align: center;
    font-size: 20px;
    border-bottom: 1px solid black;
    padding: 5px;
}

.db-ls-label {
    display: block;
}

.dialog-leftSide {
    background-size: cover;
    background: lightblue;
}

.dialog-rightSide {
    float: right;
    text-align: center;
    background: lightcoral center;
}

.split {
    width: 50%;
    height:60px;
    position: static;
    display: inline-block;
    top: 0;
    overflow-x: hidden;
    padding-top: 10px;
    padding-bottom: 10px;
}

.db-footer {
    border-top: 1px solid black;
    padding: 10px;
    text-align: center;
}

.db-footer button {
    background-color: rgb(151, 52, 132);
    border-radius: 4px;
    width: 110px;
    height: 40px;
    font-size: 15px;
    color: white
}

.db-main-lottie {
    background-color: black;
}

/*Radio Button Options - NothingSelected*/
.rdo-nothingselected-p {
    margin: 20px;
}

/*Radio Button Options - DivvyCloudAuthentication*/
.rdo-divvycloudauthentication-div {
    margin: 7px;
}

.rdo-divvycloudauthentication-label {
    display: inline-block;
    float: left;
    margin-left: 3px;
    margin-bottom: 3px;
    text-align: right;
    font-size: 15px;
}

.rdo-divvycloudauthentication-label-input {
    margin-left: 4px;
}

.rdo-divvycloudauthentication-label-error {
    margin-left: 4px;
    background: url(./x-mark-10.jpg) white no-repeat scroll right;
}

.rdo-divvycloudauthentication-button {
    float: right;
    height: 45px;
    margin-right: 10px;
    background-color: rgb(151, 52, 132);
    border-radius: 4px;
    color: white;
}

.rdo-divvycloudauthentication-div-error {
    font-size:10px;
    float: left;
}

/*Radio Button Options - DownloadJSON*/
.rdo-downloadjson-div {
    margin: -9px;
}

.rdo-downloadjson-p {
    margin: 6px;
}

.rdo-downloadjson-button {
    align-content: center;
    background-color: rgb(151, 52, 132);
    border-radius: 4px;
    width: 100px;
    height: 25px;
    margin: 10px;
    color: white;
}

/*Radio Button Options - SubmitJSON*/
.rdo-submitjson-div {
    margin: -9px;
}

.rdo-submitjson-p {
    margin: 6px;
}

.rdo-submitjson-button {
    margin: 10px;
    background-color: rgb(151, 52, 132);
    border-radius: 4px;
    width: 100px;
    height: 25px;
    color: white;
}