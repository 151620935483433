/*LandingPage Component*/
.lp-div {
    background: url(Images&Icons/background.png) no-repeat center center fixed;
    position: fixed;
    min-height: 100%;
    min-width: 100%;
    background-size: cover;
    color: white;
}

#lp-header {
    text-align: center;
    font-size: 3rem;
}

#nephotools, #research {
    display: inline-block;
}

#nephotools, #research figurecaption {
    text-align: center;
}

#lp-div-figure {
    text-align: center;
    align-content: center;
}

/*Navigation Component*/
.nav-nav {
    display: flex;
    justify-content: left;
    height: 90px;
    background: white;
    align-content: center;
    border-bottom: 1px solid black;
}

#nav-logo {
    position: relative;
    width: 70px;
    height: 70px;
    padding: 10px;
}

#nav-text {
    width: 310px;
    height: 50px;
    position: relative;
    top: 25%;
}

/*Modal Popup Component*/
.modal-overall {
    color: black;
}

.modal-header {
    font-size: 20px;
}

.modal-content ul li {
    list-style: none;
}

/*Edit Existing Components(includes Pack and Bot)*/
.edit-div {
    background: url(Images&Icons/Grey_grid_Repeatable.png) repeat center center fixed;
    position: absolute;
    overflow-y: auto;
    min-height: 100%;
    min-width: 100%;
    display: block;
}

.edit-leftside {
    float: left;
    overflow: scroll;
    max-width: 600px;
}

.edit-rightside {
    float: right;
    height: 700px;
    width: 800px;
    margin: 5px;
    padding-top: 10px;
    padding-bottom: 20px;
    padding-right: 20px;
    position: static;
}

.edit-json {
    position: static;
    height: 100%;
    overflow: scroll;
    background-color: #282824;
}

.edit-leftdiv-formdiv {
    overflow: auto;
}

#browser {
    margin: 5px;

}

.__json-pretty__ {
    line-height: 1.3;
    color: #66d9ef;
    background: #272822;
    overflow: auto;
    white-space: pre-wrap;
}

.__json-pretty__ .__json-key__ {
    color: #ff0f67;
    word-wrap: normal;
}

.__json-pretty__ .__json-value__ {
    color: #a6e22e;
    word-wrap: normal;
}

.__json-pretty__ .__json-string__ {
    color: #fd971f;
    word-wrap: normal;
}

.__json-pretty__ .__json-boolean__ {
    color: #ac81fe;
    word-wrap: normal;
}

.__json-pretty-error__ {
    line-height: 1.3;
    color: #66d9ef;
    background: #272822;
    overflow: auto;
    word-wrap: normal;
}

.edit-form-header {
    color: rgb(151, 52, 132);
    display: block;
    font-size: 2em;
    margin-top: 5px;
    margin-bottom: 0px;
}


.edit-form-div-messageBox {
    border: 1px solid black;
}

.edit-form-div-messageBox footer {
    border-top: 1px black solid;
    margin: 5px;
}

.edit-form-div-messageBox footer textarea {
    margin: 5px;
    max-width: 530px;
    max-height: 248px;
    min-width: 530px;
    min-height: 248px;
}

.edit-form-label {
    margin: 10px;
    display: block;
    overflow-wrap: normal;
}

.edit-form-label-textarea {
    margin: 10px;
    display: block;
    overflow-wrap: normal;
}

.edit-form-label-textarea textarea {
    display: block;
    min-width: 350px;
    min-height: 50px;
    max-width: 400px;
    max-height: 100px;
}

.__json-pretty__ {
    line-height: 1.3;
    color: #66d9ef;
    background: #272822;
    white-space: pre-wrap;
}

.__json-pretty__ .__json-key__ {
    color: #f92672
}

.__json-pretty__ .__json-value__ {
    color: #a6e22e
}

.__json-pretty__ .__json-string__ {
    color: #fd971f
}

.__json-pretty__ .__json-boolean__ {
    color: #ac81fe
}

.edit-json > .__json-string__ {
    text-indent: -2em;
}

.__json-pretty-error__ {
    line-height: 1.3;
    color: #66d9ef;
    background: #272822;
}

.edit-dc-form-label {
    margin-right: 20px;
}

.edit-dc-form-label input {
    margin-left: 5px;
}


.edit-form-label input {
    margin-left: 5px;
}

.edit-leftside {
    padding-left: 10px;
}

.edit-leftside form {
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

#edit-form-submit {
    background-color: rgb(151, 52, 132);
    border-radius: 4px;
    width: 100px;
    height: 30px;
    font-size: 15px;
    color: #ffffff;
    margin-top: 10px;
}

.formControl {
    margin: 8px;
    min-width: 200px;
    max-width: 450px;
}

.chips {
    display: flex;
    flex-wrap: wrap;
}

.chip {
    margin: 2px;
}

.json_data-button {
    float: left;
    width: 20px;
    height: 38px;
    padding-top: 8px;
    padding-bottom: 2px;
}

.json_data-select {
    float: right;
    width: 578px;
}

/*Create New Pack Component*/
.create-div {
    background: url(Images&Icons/Grey_grid_Repeatable.png) repeat center center fixed;
    position: fixed;
    min-height: 100%;
    min-width: 100%;
}

.create-div-form {
    padding: 10px;
}

#create-dc-label {
    margin-top: 10px;
    margin-right: 20px;
}

#create-bots-label {
    margin: 10px;
    display: block;
}

#create-bots-label input {
    margin-left: 5px;
}

#create-form-submit {
    background-color: rgb(151, 52, 132);
    border-radius: 4px;
    width: 100px;
    height: 30px;
    font-size: 15px;
    color: #ffffff;
}